import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getGrantReportService } from "../services/GrantService";
import "../styles/Report.css";
import { ViewDollar } from "../utils";
import { useReactToPrint } from "react-to-print";

const Report = () => {
   const { grant_id } = useParams();
   const [content, setContent] = useState(undefined);
   // budget filter start
   const [selectedBudget, setSelectedBudget] = useState(null);
   const handleBudgetSelect = (budget) => {
      setSelectedBudget(budget);
      setContent({ ...content, budgets: [budget] }); // Update the content state with the selected budget
   };
   // budget filter end
   const componenteRef = useRef();
   const prinData = useReactToPrint({
      content: () => componenteRef.current,
      bodyClass: "container",
      copyStyles: true,
   });

   useEffect(() => {
      getGrantReport(grant_id);
   }, [grant_id]);

   const getGrantReport = async (grant_id) => {
      const data = await getGrantReportService(grant_id);
      setContent(data);
      console.log(data)
   };

   return (
      <>
         {/* Print Button */}
         <div className="mt-4 mb-3 text-center">
            <button className="btn btn-primary px-4" onClick={prinData}>
               Print
            </button>
         </div>

         {/* Budget List */}
         <div className="text-center">
            <h1>Budget List</h1>
            <ul>
               {content?.budgets?.map((budget, index) => (
                  <li className="pointer" key={index} onClick={() => handleBudgetSelect(budget)}>
                     {budget.description}
                  </li>
               ))}
            </ul>
         </div>

         {/* Mobile Disclaimer */}
         <div className="d-md-none text-center">
            <p>DISCLAIMER: On mobile you can only print the report.</p>
         </div>

         {/* Main Report Container - Fully Centered */}
         <div ref={componenteRef} className="container mx-auto d-flex flex-column align-items-center w-100 hide-on-website">
            
            {/* Report Header */}
            <div className="text-center row pb-2 mx-auto w-100 gx-0">
               <div className="row subtitle p-0 w-100 mx-auto gx-0">
                  <h2 className="col-12 grantTitle m-0 py-2">
                     General Report by Grant
                  </h2>
                  <div className="col pt-2 text-center">{content && content.name}</div>
                  <div className="col pt-2 text-center">{content && ViewDollar(content.amount)}</div>
                  <div className="col pt-2 text-center">{content && ViewDollar(content.amountMinusTotalExpense)}</div>
               </div>

               {/* Table Headers */}
               <div className="row py-2 subtitle fw-bold w-100 mx-auto text-center gx-0">
                  <div className="col">Object</div>
                  <div className="col">Contract</div>
                  <div className="col">Description</div>
                  <div className="col">Department / Program</div>
                  <div className="col">Contract Amount</div>
                  <div className="col">Expenses</div>
                  <div className="col">Remaining</div>
                  <div className="col">Remaining (%)</div>
               </div>

               {/* Budget Rows */}
               {content &&
                  content.budgets.map((budget, index) => (
                     <div key={index} className="w-100 mx-auto text-center">
                        {/* Budget Data Row */}
                        <div className="row py-2 bgTable mt-3 w-100 mx-auto text-center gx-0">
                           <div className="col">{budget.object}</div>
                           <div className="col">{budget.function}</div>
                           <div className="col">{budget.description}</div>
                           <div className="col">{budget.department_program}</div>
                           <div className="col">{ViewDollar(budget.contract_amount)}</div>
                           <div className="col">{ViewDollar(budget.expenses)}</div>
                           <div className="col">{ViewDollar(budget.remaining)}</div>
                           <div className="col">{parseInt(budget.remaining_percentage).toFixed(1)} %</div>
                        </div>

                        {/* Contracts Section */}
                        {budget.contracts.length > 0 && (
                           <div className="w-100 mx-auto">
                              <div className="row py-2 contractTitle mt-3 w-100 mx-auto text-center gx-0">
                                 <div className="col">
                                    Contract: {budget.object} / {budget.function} / {budget.description}
                                 </div>
                                 <div className="col-2">{ViewDollar(budget.contract_amount)}</div>
                                 <div className="col-2">{ViewDollar(budget.assigned_remaining)}</div>
                                 <div className="col-2">{ViewDollar(budget.expenses)}</div>
                              </div>

                              {/* Contract Table Headers */}
                              <div className="row py-2 subtitle fw-bold w-100 mx-auto text-center gx-0">
                                 <div className="col">Contract / PO</div>
                                 <div className="col">Company / Name</div>
                                 <div className="col">Department / Program</div>
                                 <div className="col">Contract Start</div>
                                 <div className="col">Contract End</div>
                                 <div className="col">Contract Amount</div>
                                 <div className="col">Expenses</div>
                              </div>

                              {/* Contract Rows */}
                              {budget.contracts.map((contract, index) => (
                                 <div className="row py-2 bgContractTable w-100 mx-auto text-center gx-0" key={index}>
                                    <div className="col">{contract.contract_po}</div>
                                    <div className="col">{contract.company_name}</div>
                                    <div className="col">{contract.department_program}</div>
                                    <div className="col">{contract.contract_start_date}</div>
                                    <div className="col">{contract.contract_end_date}</div>
                                    <div className="col">{ViewDollar(contract.contract_amount)}</div>
                                    <div className="col">{ViewDollar(contract.encumbrances_sum)}</div>
                                 </div>
                              ))}
                           </div>
                        )}
                     </div>
                  ))}
            </div>
         </div>
      </>
   );
};

export default Report;
